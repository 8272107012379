import React from "react";
import styles from "../../styles/contactSocial.module.scss";
import { IconContext } from "@react-icons/all-files";
import { FaEnvelope } from "@react-icons/all-files/fa/FaEnvelope";

export default function Email() {
  return (
    <a className={ styles.email } href="mailto:amysitwala@gmail.com">
      <IconContext.Provider value={{ color: '#FF9A23' }}>
        <FaEnvelope className={ `icon__1px__down icon__left` } />
        <span>amysitwala@gmail.com</span>
      </IconContext.Provider>
    </a>
  );
};
