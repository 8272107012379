import React, { useState, useEffect } from "react";
import { navigate } from "gatsby";
import Recaptcha from 'react-google-recaptcha';
import { withStyles } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { FaTelegramPlane } from "@react-icons/all-files/fa/FaTelegramPlane";
import { Alert, AlertTitle } from '@material-ui/lab';

import { EMAIL_REGEX } from "../../utils/emailRegex";

import styles from "../../styles/contactMessage.module.scss";

const RECAPTCHA_KEY = process.env.GATSBY_SITE_RECAPTCHA_KEY;

const CSSTextField = withStyles({
  root: {
    '& label, & label.Mui-focused': {
      color: '#071467',
    },
    '& .MuiOutlinedInput-root': {
      backgroundColor: '#FFF8EF',

      '& fieldset': {
        borderColor: '#FFD29C',
      },
      '&:hover fieldset': {
        borderColor: '#243396',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#243396',
      },
    },
    '& .MuiFormHelperText-root': {
      marginTop: '8px',
      fontSize: '14px',
      fontFamily: "'Source Sans Pro', Helvetica, sans-serif",
    },
    /* Errors */
    '& .MuiOutlinedInput-root.Mui-error': {
      '& fieldset, &:hover fieldset, &.Mui-focused fieldset': {
        borderColor: '#f44336',
      },
    },
    '& label.Mui-error, & label.Mui-error.Mui-focused': {
      color: '#f44336',
    },
  }
})(TextField);


export default function Message() {

  const [ loading, setLoading ] = useState(false);

  const [ values, setValues ] = useState({
    name: "",
    email: "",
    message: "",
    recaptcha: "",
  });
  
  const [ errors, setErrors ] = useState({
    name: "",
    email: "",
    message: "",
    recaptcha: "",
  });

  const [ processingError, setProcessingError ] = useState(false);

  useEffect(() => {
  }, [loading, values, errors, processingError ]);


  function handleChange(event, key) {
    /* Remove errors */
    if (errors[key]) {
      setErrors({...errors, [key]: '' });
    }

    if (processingError) {
      setProcessingError(false);
    }

    setValues({...values, [key]: key === "recaptcha" ? event : event.target.value });
  }


  function encode(data) {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
  };


  /* References: 
    * https://docs.netlify.com/forms/setup/ 
    * https://docs.netlify.com/forms/setup/#submit-javascript-rendered-forms-with-ajax
    * https://blog.larsbehrenberg.com/how-to-create-a-contact-form-with-recaptcha-hosted-on-netlify
  */

  function sendMessage(event) {
    event.preventDefault();

    /* Validate here */
    const newErrors = {
      name: !values.name ? "Name is required." : "",
      email: !values.email ? "Email is required." : (!EMAIL_REGEX.test(values.email) ? "Please provide a valid email." : ""),
      message: !values.message ? "Message is required." : "",
      recaptcha: !values.recaptcha ? "Check the box above." : ""
    };

    setErrors(newErrors);
    const isValid = !newErrors.name && !newErrors.email && !newErrors.message && !newErrors.recaptcha;

    if (isValid) { // Send message 
      setLoading(true);
      const form = event.target;

      // Get values to send
      const { recaptcha, ...otherValues } = values;

      fetch('/', {
        method: 'POST',
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({
          "form-name": form.getAttribute("name"),
          "netlify-honeypot": form.getAttribute("netlify-honeypot"),
          "g-recaptcha-response": recaptcha,
          ...otherValues
        }),
      }).then(() => {
        setLoading(false);
        navigate(form.getAttribute("action"));
      }).catch((error) => {
        console.error(error);
        setProcessingError(true);
        setLoading(false);
      });
    }
  }

  return (
    <form
      data-netlify="true"
      netlify-honeypot="bot-field"
      data-netlify-recaptcha="true"
      name="contact"
      method="POST"
      action="/contact-success"
      onSubmit={(evt) => sendMessage(evt)}>
      <noscript>
        <p>This form won't work with Javascript disabled.</p>
      </noscript>
      <input type="hidden" name="form-name" value="contact" />
      <div className={ styles.hidden }>
        <input name="bot-field" />
      </div>
      <Grid container spacing={4} className={ styles.form }>
        <Grid item xs={12} sm={6} >
          <CSSTextField 
            variant="outlined"
            label="Name"
            name="name" 
            fullWidth
            value={ values.name }
            onChange={ (evt) => handleChange(evt, "name") }
            error={ !!errors.name }
            helperText={ errors.name }
          />
        </Grid>
        <Grid item xs={12} sm={6} >
          <CSSTextField
            variant="outlined"
            label="Email"
            name="email"
            fullWidth
            value={ values.email }
            onChange={ (evt) => handleChange(evt, "email") }
            error={ !!errors.email }
            helperText={ errors.email }
          />
        </Grid>
        <Grid item xs={12}>
          <CSSTextField
            variant="outlined"
            label="Message"
            name="message"
            fullWidth 
            multiline 
            rows={6} 
            className={ styles.textarea }
            value={ values.message }
            onChange={ (evt) => handleChange(evt, "message") }
            error={ !!errors.message }
            helperText={ errors.message }
          />
          </Grid>
      </Grid>

      {/* GOOGLE RECAPTCHA */}
      <div className={ styles.recaptcha__container }>
        <Recaptcha
          sitekey={RECAPTCHA_KEY}
          size="normal"
          id="recaptcha-google"
          className={ `${ styles.recaptcha } ${ errors.recaptcha ? styles.recaptcha__error : '' }` }
          onChange={(evt) => handleChange(evt, "recaptcha")}
        />

        { errors.recaptcha 
          ? <p className={ styles.custom__error }>{ errors.recaptcha }</p>
          : null
        }
      </div>

      {/* ERRORS */}
      { (errors.name || errors.email || errors.message || errors.recaptcha )
        ? <Alert severity="error" className={ styles.alert }><b>Please correct the errors above.</b></Alert>
        : null
      }

      { processingError
        ? <Alert severity="error" className={ styles.alert }>
          <AlertTitle className={ styles.alert__title }><b>Sorry, it looks like something went wrong on our end.</b></AlertTitle>
          <p>Your message couldn't be sent at this time. To get in contact with me, please try again or email me at <a className="link" href="mailto:amysitwala@gmail.com">
              amysitwala@gmail.com
            </a>.
          </p>
        </Alert>
        : null
      }

      <Button className={ styles.button__container } size="large" type="submit" variant="contained" color="primary" disabled={ loading } disableElevation>
        { loading
          ? <div className={ styles.button }>Sending <CircularProgress color="inherit" indeterminate="true" size={16} className={ `icon__right` }/></div>
          : <div className={ styles.button }>Send Message <FaTelegramPlane className={ `icon__2px__down icon__right`} /> </div>
        }
      </Button>
    </form>
  );
};
